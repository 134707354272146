<template>
    <div class="page">
        <a-card style="background-color: #fff; " class="main-box">
            <!-- // 101货堆 102 宅配103 提货 -->
            <div
                style="height: calc(100vh - 95px);display:flex;border: 1px solid #F3F3F3;border-bottom: 0;width: 100%;">
                <div class="left-top" style="position: relative;">
                    <div style="display:flex;align-items: center;padding:20px 10px;color:#010101;font-size:13px;">
                        <div style="width:120px;"> <span style="color:red">*</span> 方案名称：</div>
                        <a-input v-model="planName" :max-length="20" placeholder="请输入方案名称" />
                        <a @click="editName" style="width:60px;text-align:center;font-size:14px;"
                            v-if="this.$route.query.planName">保存</a>
                    </div>
                    <div style="padding: 0 10px 10px;"
                        v-if="$route.query.subType == 102 || $route.query.subType == 103">
                        <a-button style="width:100%;border: 1px solid #5542F6;color: #5542F6;"
                            @click="addCombination">创建组合</a-button>
                    </div>
                    <!--  组列表 -->
                    <div class="prog-mai" v-if="groupList.length > 0">
                        <a-checkbox-group @change="selectChange" :disabled="$route.query.subType == 101">
                            <a-list class="demo-loadmore-list" :loading="loading" item-layout="horizontal"
                                :data-source="groupList">
                                <div v-if="showLoadingMore" slot="loadMore"
                                    :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
                                    <a-spin v-if="loadingMore" />
                                    <a-button v-else @click="onLoadMore">
                                        loading more
                                    </a-button>
                                </div>
                                <a-list-item slot="renderItem" slot-scope="item,index">
                                    <div class="prog-mai-item">
                                        <div class="prog-tit" slot="header">
                                            <div style="display:flex;align-items: center;width:100%;padding-left: 8px;">
                                                <a-checkbox :value="item.id" style="margin-right: 10px;" />
                                                <p v-if="$route.query.subType == 102 || $route.query.subType == 103">
                                                    <a-input v-model="item.name" @blur="editGroupName(index)"
                                                        placeholder="组合名称" size="small"
                                                        style="width:60px;margin-right: 5px;"></a-input>
                                                    <!-- <span style="cursor: pointer;font-size: 13px;">确认</span> -->
                                                </p>
                                                <p style="margin-left: auto;font-size: 13px;">
                                                    <span v-if="getCostPrice(index) > 0">合计优加底价：¥{{
                            getCostPrice(index).toFixed(2)
                        }}</span>
                                                    <a-icon v-if="!item.open" @click="checkOpen(index)"
                                                        type="down-circle" style="font-size:14px;cursor: pointer;" />
                                                    <a-icon v-if="item.open" @click="checkOpen(index)" type="up-circle"
                                                        style="font-size:14px;cursor: pointer;" />
                                                </p>
                                            </div>
                                        </div>
                                        <div class="prog-list" v-if="item.open">
                                            <ul
                                                v-if="item.editNeedOrderPlanGroupDetailParamList && item.editNeedOrderPlanGroupDetailParamList.length > 0">
                                                <li v-for="(goods, goodsIndex) in item.editNeedOrderPlanGroupDetailParamList"
                                                    :class="{ 'redCard': goods.status == 2 || goods.stock < 30 }"
                                                    :key="goods.id">
                                                    <span v-if="goods.status == 2" class="goods-tips">下架</span>
                                                    <span v-if="goods.stock < 30 && goods.status != 2"
                                                        class="goods-tips">不足</span>
                                                    <div class="prog-ls-top">
                                                        <img :src="goods.imagePath" alt="">
                                                        <img class="close-icon"
                                                            src="https://qiniu.youjiamall.cn/xdel.png"
                                                            @click="deleteGoods(index, goodsIndex)"
                                                            style="width: 20px;height: 20px;" alt="">
                                                        <div style="width: 80%;">
                                                            <p class="ls-top-tit text-wrap2">{{ goods.name }}
                                                            </p>
                                                            <a-popover>
                                                                <template slot="content">
                                                                    7天无理由退换货
                                                                </template>
                                                                <a href="javascript:;" style="cursor: default;"
                                                                    v-if="goods.returnable == 1">7</a>
                                                            </a-popover>
                                                            <a href="javascript:;" style="cursor: default;" v-if="goods.isQirPic == 1">质</a>
                                                        </div>
                                                    </div>
                                                    <div class="prog-ls-btm">
                                                        <div style="display: flex;">
                                                            <p>
                                                                <span>供应商 ID：</span>{{ goods.supplierId }}
                                                            </p>
                                                            <p style="margin-left: 20px;">
                                                                <span>市场建议零售价：</span>¥{{ goods.plfPrice }}
                                                            </p>
                                                        </div>
                                                        <p>
                                                            <span>优加底价：</span>¥{{ goods.platformDiscountPrice }}
                                                        </p>
                                                    </div>
                                                    <div class="prog-ls-btm">
                                                        <div style="display: flex;align-items: center;">
                                                            <p>规格:{{ goods.specsAttributeValue }}</p>
                                                            <p style="margin-left:10px;">数量：<a-input size="small"
                                                                    style="width:30px;" v-model="goods.goodsSpecCount"
                                                                    @focus="onFocus"
                                                                    @blur="editGoodsNumber(index, item.id, goodsIndex)"></a-input>
                                                            </p>
                                                            <p
                                                                style="background:#E8ECF9;padding: 0 4px;margin-left:6px;">
                                                                <a v-if="goods.thirdUrl" :href="goods.thirdUrl"
                                                                    target="_blank">H</a>
                                                            </p>

                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div v-else
                                                style="background: rgba(0,0,0,.1);width: 100%;height: 100px;text-align: center;border-radius: 4px;line-height: 100px;">
                                                等待添加商品...
                                            </div>
                                        </div>
                                    </div>
                                </a-list-item>
                            </a-list>
                        </a-checkbox-group>
                    </div>
                    <div v-else style="padding-top: 200px;">
                        <a-empty :image="simpleImage" />
                    </div>
                    <div class="prog-btm">

                        <p v-if="$route.query.subType == 101" style="color:red;font-weight:400;margin-bottom:10px;">
                            提示：合计优加底价不能高于【产品预算】，高于则无法创建方案</p>
                        <p v-if="$route.query.subType == 102 || $route.query.subType == 103"
                            style="color:red;font-weight:400;margin-bottom:10px;">
                            提示：每组方案合计优加底价不能高于【产品预算/份*采购数量】，高于则无法创建方案</p>
                        <div ref="progBtm" style=" display: flex;align-items: center;justify-content: space-around;">
                            <a-button v-if="$route.query.subType == 102 || $route.query.subType == 103"
                                style="margin-left: 8px;border: 1px solid #fff;color: #fff;background: #5542F6;"
                                @click="delConfirm">删除</a-button>
                            <a-button v-if="$route.query.planStatus != 10" style="background-color: #fff;color: #5542F6"
                                @click="groupSave">生成方案</a-button>
                        </div>
                    </div>

                    <div style="height: 62px;"></div>
                </div>
                <!-- 产品池 -->
                <div class="items-right">
                    <div :class="advanced ? 'search' : null">
                        <a-form layout="horizontal">
                            <div :class="advanced ? null : 'fold'">
                                <div
                                    style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
                                    <a-icon style="font-size: 30px;margin-right: 10px;" @click="backClick"
                                        type="left-circle" />
                                    <span @click="backClick">返回</span>
                                    <span style="color:red;font-size: 14px;margin-left:5px">当前方案中新增、修改、删除
                                        组合和商品会实时生效</span>
                                </div>
                                <a-row style="display:flex;flex-wrap:wrap;align-items: center;padding: 10px 0;">
                                    <a-form-item label="商品名称" :labelCol="{ span: 6 }"
                                        :wrapperCol="{ span: 14, offset: 1 }">
                                        <a-input v-model="queryData.name" placeholder="请输入商品名称" />
                                    </a-form-item>
                                    <a-form-item label="供应商ID" :labelCol="{ span: 6 }"
                                        :wrapperCol="{ span: 14, offset: 1 }">
                                        <a-input placeholder="请输入商品ID" v-model="queryData.supplierId" type="number" />
                                    </a-form-item>
                                    <a-form-item label="质检报告" :labelCol="{ span: 8 }"
                                        :wrapperCol="{ span: 14, offset: 1 }">
                                        <a-select v-model="queryData.isQirPic" style="width: 120px" placeholder="可多选"
                                            option-label-prop="label">
                                            <a-select-option value="1" label="是">
                                                是
                                            </a-select-option>
                                            <a-select-option value="0" label="否">
                                                否
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item label="7天无理由" :labelCol="{ span: 8 }" style="margin-left:10px"
                                        :wrapperCol="{ span: 14, offset: 1 }">
                                        <a-select v-model="queryData.returnable" style="width: 120px" placeholder="请选择"
                                            option-label-prop="label">
                                            <a-select-option value="1" label="支持">
                                                支持
                                            </a-select-option>
                                            <a-select-option value="2" label="不支持">
                                                不支持
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>


                                    <!-- <a-form-item label="分类" :labelCol="{ span: 8 }"
                                        :wrapperCol="{ span: 14, offset: 1 }">
                                        <a-select allowClear v-model="firstClassifyId" placeholder="请选择一级分类"
                                            option-label-prop="label">
                                            <a-select-option :value="item.id" v-for="item in firstList" :key="item.id"
                                                :label="item.name" @click="pIdChange(item.id, 0)">{{ item.name
                                                }}</a-select-option>
                                        </a-select>
                                        <a-select allowClear v-model="secondClassifyId" placeholder="请选择二级分类"
                                            option-label-prop="label">
                                            <a-select-option :value="item.id" v-for="item in secondList" :key="item.id"
                                                :label="item.name" @click="pIdChange(item.id, 1)">{{ item.name
                                                }}</a-select-option>
                                        </a-select>
                                        <a-select allowClear v-model="tertiaryClassifyId"
                                            placeholder="请选择三级分类" option-label-prop="label">
                                            <a-select-option :value="item.id" v-for="item in tertiaryList"
                                                :key="item.id" :label="item.name" @click="pIdChange(item.pId, 2)">{{
                            item.name }}</a-select-option>
                                        </a-select>
                                    </a-form-item> -->



                                    <div style="display: flex;align-items: center;width:200px;margin: 0 10px">
                                        <span style="color:rgba(0, 0, 0, 0.85);">优加底价：</span>
                                        <a-input style="flex:1" v-model="queryData.minPrice" placeholder="最小" />
                                        <span>-</span>
                                        <a-input style="flex:1" v-model="queryData.maxPrice" placeholder="最大" />
                                    </div>

                                    <div style="display: flex;align-items: center;width:200px;margin-right:10px">
                                        <span style="color:rgba(0, 0, 0, 0.85);">电商售价：</span>
                                        <a-input style="flex:1" v-model="queryData.minMarketPrice" placeholder="最小" />
                                        <span>-</span>
                                        <a-input style="flex:1" v-model="queryData.maxMarketPrice" placeholder="最大" />
                                    </div>
                                    <a-button style="margin-right: 8px;width: 80px;" @click="resetChange">重置</a-button>
                                    <a-button type="primary" style="width: 80px;" @click="searchChange">查询</a-button>
                                </a-row>
                                <a-divider />
                                <div class="select-list">
                                    <ul>
                                        <li v-for="(item, index) in goodsType"
                                            :class="typeCurrent == index ? 'active-sel' : ''" :key="item.id"
                                            @click="filterTypeChange(index)">
                                            <img v-if="typeCurrent == index" :src="item.selIcon" alt="">
                                            <img v-else :src="item.icon" alt="">
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                    <!-- <ul>
                                        <li v-for="(item, index) in orderType"
                                            :class="ordCurrent == index ? 'active-sel' : ''" :key="item.id"
                                            @click="filterOrderChange(index)">
                                            <img v-if="ordCurrent == index" :src="item.selIcon" alt="">
                                            <img v-else :src="item.icon" alt="">
                                            {{ item.name }}
                                        </li>
                                    </ul> -->
                                    <span style="color:red;">提示：部分商品没有电商价格或相关链接</span>
                                </div>
                            </div>
                        </a-form>
                    </div>
                    <div>
                        <ul v-if="prodList.length > 0">
                            <li v-for="(item, index) in prodList" :key="index">
                                <div class="items-tag" v-if="item.type == 2">礼包</div>
                                <p style="text-align: center;" class="rig-img-box">
                                    <img :src="item.imagePath" style="object-fit: cover;width: 100%;height: 100%;"
                                        alt="">
                                </p>
                                <div class="prog-ls-top items-ls-top">
                                    <p class="ls-top-tit text-wrap1">{{ item.name }}</p>
                                    <p style="height:30px;display: flex;align-items:center;">
                                        <a @click="openH(item.thirdUrl)" target="_blank"
                                            v-if="item.thirdUrl">H</a>
                                        <a-popover>
                                            <template slot="content">
                                                7天无理由退换货
                                            </template>
                                            <a style="cursor: default;" href="javascript:;" v-if="item.returnable == 1">7</a>
                                        </a-popover>

                                        <a-popover>
                                            <template slot="content">
                                                有质检报告
                                            </template>
                                            <a style="cursor: default;" href="javascript:;" v-if="item.isQirPic == 1">质</a>
                                        </a-popover>
                                    </p>
                                </div>
                                <div class="prog-ls-btm" style="padding-top: 0;">
                                    <p>
                                        <span>供应商 ID：</span>{{ item.supplierId }}
                                    </p>
                                    <p>
                                        <span>电商售价：</span>¥{{ item.marketPrice }} <span v-if="item.threeTypeName">({{
                            item.threeTypeName
                        }})</span>
                                    </p>
                                    <p>
                                        <span>市场建议零售价：</span>¥{{ item.plfPrice }}
                                    </p>
                                    <p>
                                        <span>优加底价：</span>¥{{ item.platformDiscountPrice }}
                                    </p>
                                    <p>
                                        <span>创建时间：</span>{{ formatDate(item.createTime) }}
                                    </p>
                                </div>

                                <div class="items-btn">
                                    <a-button style="width:100%;" @click="createProg(item, 0)"
                                        v-if="$route.query.subType == 102 || $route.query.subType == 103">加入组合</a-button>
                                    <a-button style="width:100%;" @click="createProg(item, 0)" v-else>加入方案</a-button>
                                </div>
                            </li>


                        </ul>

                        <div v-else class="items-empty">
                            <a-empty :image="simpleImage" />
                        </div>

                        <div class="page-box">
                            <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size"
                                @change="handlePageChange" />
                        </div>
                    </div>


                </div>


                <!-- 选择加入哪个方案 / 规格 -->
                <a-modal v-model="selectVisible" title="请选择" @ok="addProjectConfirm">
                    <p style="color:red;">提示：选择的商品规格默认数量为1，若要更改请在组合列表添加</p>
                    <p style="font-size:14px;color:#010101;margin:10px 0;"><span style="color:red">*</span> 规格（可多选）
                    </p>
                    <a-checkbox-group @change="specsSelectChange" v-model="checkList">
                        <div v-for="(item, index) in specsList" :key="index" style="margin-bottom: 10px;">
                            <a-checkbox :value="item.goodsSpecId">
                                {{ item.specsAttributeValue }}
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>

                    <div v-if="$route.query.subType == 102 || $route.query.subType == 103">
                        <p style="font-size:14px;color:#010101;margin:10px 0;"><span style="color:red">*</span>组合</p>
                        <a-radio-group v-model="checkedGroup" @change="popSelectChange">
                            <div v-for="(item, index) in groupList" :key="index" style="margin-bottom: 10px;">
                                <a-radio :value="item.id">
                                    {{ item.name }}
                                </a-radio>
                            </div>
                        </a-radio-group>
                    </div>
                </a-modal>
            </div>

        </a-card>
    </div>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import { request } from '@/utils/request'
import { Empty } from 'ant-design-vue';
import { EventBus } from '@/utils/event-bus.js';

export default {
    name: 'QueryList',
    // components: { StandardTable },
    data() {
        return {
            iptmd: 4,
            iptsm: 12,
            form: this.$form.createForm(this),
            queryData: {
                "page": 0,
                "size": 16,
            },
            currentPage: 1,

            goodsType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '单品', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon4.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon04.png' }, { name: '礼包', id: 2, icon: 'https://qiniu.youjiamall.cn/tupianxs.png', selIcon: 'https://qiniu.youjiamall.cn/tupianxs1.png' }],
            // orderType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '热销', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon2.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon02.png' }, { name: '新品', id: 2, icon: 'https://qiniu.youjiamall.cn/xsicon1.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon01.png' }],
            ordCurrent: 0,
            typeCurrent: 0,


            advanced: true,
            dataSource: [],
            selectedRows: [],
            pagination: {
                current: 1,
                pageSize: 12,
                total: 0
            },
            total: 0,
            current: 1,
            // 新建方案
            ModalText: 'Content of the modal',
            visible: false,
            confirmLoading: false,

            // 新建方案弹窗
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },

            prodList: [],

            groupList: [],

            selectVisible: false,//加入方案弹窗
            checkedGroup: "",//组id
            delVisible: false,//确认删除弹窗

            // 排序参数
            groupParams: {
                "page": 0,
                "size": 100,
                "createTimeOrderBy": "asc",
                "updateTimeOrderBy": "",
                "marketPriceOrderBy": "",
                "profitMarginOrderBy": ""
            },

            classfiyData: {},
            progDetail: {},
            loading: true,
            loadingMore: false,
            showLoadingMore: false,
            addCurrentIndex: 0,//全局组合数量
            specsList: [],//规格列表
            checkList: [],//规格id
            prodSelectList: [],
            planName: "方案",//方案名称
            currentProjectData: null,
            firstList: [],
            secondList: [],
            tertiaryList: [],
            firstClassifyId: "",
            secondClassifyId: "",
            tertiaryClassifyId: "",
            delIndex: -1,

        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        this.getData();//查询列表
        // request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/' + this.queryData.classifyType + '/' + this.pId + '/' + 11, 'get').then(res => {
        //     this.firstList = res.data.data;
        // })
        // 编辑
        if (this.$route.query.planId) {
            this.planName = this.$route.query.planName
            this.getGroupList();//查询组
        }
        this.getGoodsClassifyList()
        // this.getProgDetail();//查详情
        if (this.$route.query.subType == 101) {
            this.loading = false;
            this.groupList = [{ name: '组合', value: 0, costPrice: 0, profit: 0, profitRate: 0, open: true, editNeedOrderPlanGroupDetailParamList: [] }]
        }
    },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'validate_other' });
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        },

    },
    watch: {
        'firstClassifyId': function () {
            if (this.firstClassifyId) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/1' + '/' + this.pId + '/20220601001', 'get').then(res => {
                    this.secondList = res.data.data;
                })
            }
        },
        'secondClassifyId': function () {
            if (this.secondClassifyId) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/1' + '/' + this.pId + '/20220601001', 'get').then(res => {
                    this.tertiaryList = res.data.data;
                })
            }
        },
    },
    methods: {
        openH(url){
            window.open(url)
        },
        getGoodsClassifyList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/classify/list/1/0/20220601001', 'get').then(res => {
                this.firstList = res.data.data;
            })
        },

        pIdChange(pId, type) {
            if (type == 0) {
                this.secondClassifyId = ""
                this.secondList = []
                this.tertiaryClassifyId;
                this.tertiaryList = [];
            }
            if (type == 1) {
                this.tertiaryClassifyId = ""
                this.tertiaryList = [];
            }
            this.pId = pId;
        },
        onFocus(e) {
            this.strNumber = e.target.value
        },
        editGoodsNumber(index, id, goodsIndex) {
            if (this.$route.query.planStatus == 10) {
                let isSuccess = false;
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/update', 'post', {
                    "planGroupId": id,
                    "goodsSpecId": this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex].goodsSpecId,
                    goodsSpecCount: this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex].goodsSpecCount
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('数量更新成功！')
                        isSuccess = true;
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                setTimeout(()=>{
                    if (!isSuccess) {
                        this.$set(this.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex], 'goodsSpecCount', this.strNumber);
                    }
                },700)
            }

            if (!this.groupList[index].editNeedOrderPlanGroupDetailParamList) {
                return 0;
            }
            let price = 0;
            this.groupList[index].editNeedOrderPlanGroupDetailParamList.forEach(item => {
                price = price + item.platformDiscountPrice * item.goodsSpecCount
            })
            return price

        },
        backClick() {
            EventBus.$emit('itemRemoved');
            this.$router.go(-1);
        },
        // 修改方案名
        editName() {
            if (!this.planName) {
                this.$message.warning('请输入方案名称！')
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/update', 'post', {
                "id": this.$route.query.planId,
                "name": this.planName
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('保存成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })

        },
        // 计算名称
        editGroupName(index) {
            if(!this.$route.query.planId){
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/update', 'post', {
                planId: this.$route.query.planId,
                planGroupId: this.groupList[index].id,
                name: this.groupList[index].name

            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('组合名称修改成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
            console.log(this.groupList[index].name, 'this.groupList[index].editNeedOrderPlanGroupDetailParamList')

        },
        // 计算价格
        getCostPrice(index) {
            if (!this.groupList[index].editNeedOrderPlanGroupDetailParamList) {
                return 0;
            }
            let price = 0;
            this.groupList[index].editNeedOrderPlanGroupDetailParamList.forEach(item => {
                price = price + item.platformDiscountPrice * item.goodsSpecCount
            })
            price.toFixed(2)
            this.groupList[0].costPrice = price
            console.log(price, 'price')
            return price
        },
        // 展开收起
        checkOpen(index) {
            this.groupList[index].open = !this.groupList[index].open
            if (this.$route.query.planId) {
                this.getProgDetail(index)
            }
        },
        // 添加组合
        addCombination() {
            if (this.$route.query.planStatus == 10) {
                this.addCurrentIndex++;
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/save', 'post', { planId: this.$route.query.planId, needOrderDetailId: this.$route.query.needOrderDetailId, name: '组合' + (this.addCurrentIndex), costPrice: 0, profit: 0, profitRate: 0, }).then(res => {
                    if (res.data.code == 200) {
                        this.groupList.unshift({ needOrderDetailId: this.$route.query.needOrderDetailId, name: '组合' + (this.addCurrentIndex), id: res.data.data, costPrice: 0, profit: 0, profitRate: 0, editNeedOrderPlanGroupDetailParamList: [], open: true })
                    }
                })
            } else {
                this.addCurrentIndex++;
                this.groupList.unshift({ name: '组合' + (this.addCurrentIndex), id: this.addCurrentIndex, costPrice: 0, profit: 0, profitRate: 0, editNeedOrderPlanGroupDetailParamList: [], open: true })
                this.loading = false;
            }

        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        selectChange(checkedList) {
            console.log(checkedList, 'checkedList')
            this.delGroupIdList = checkedList;
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
            return result
        },
        // 筛选
        filterTypeChange(idx) {
            this.$message.loading();
            if (idx == 0) {
                this.queryData.type = ''
            } else {
                this.queryData.type = this.goodsType[idx].id
            }
            this.getData();
            this.typeCurrent = idx;
        },
        filterOrderChange(idx) {
            this.$message.loading();
            if (idx == 0) {
                this.queryData.order = ''
            } else {
                this.queryData.order = this.orderType[idx].id
            }
            this.getData();
            this.ordCurrent = idx;
        },
        // 生成方案
        groupSave() {
            if (this.groupList.length == 0) {
                this.$message.warning('方案组合不可为空')
                return false;
            }
            // if (!this.currentProjectData) {
            //     this.$message.warning('请添加商品！');
            //     return;
            // }
            if (!this.planName) {
                this.$message.warning('请输入方案名称！')
                return false;
            }
            // let editNeedOrderPlanGroupParamList = []
            // this.groupList.forEach(item=>{
            //     console.log(item,'item')
            // })

            let params = {
                "needOrderId": this.$route.query.needId, //需求单id
                "needOrderDetailId": this.$route.query.needOrderDetailId, //子需求单id
                "name": this.planName, //方案名称
                "editNeedOrderPlanGroupParamList": this.groupList
            }
            // console.log(this.$route.query, params, 'this.$route.query')
            // if (!this.$route.query.planId) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/save', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！')
                    EventBus.$emit('itemRemoved');
                    this.$router.go(-1);
                } else {
                    this.$message.error(res.data.message)
                }
            })
            // } else {
            //     request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlan/update', 'post', params).then(res => {
            //         if (res.data.code == 200) {
            //             this.$message.success('更新成功！')
            //             EventBus.$emit('itemRemoved');
            //             this.$router.go(-1);
            //         }
            //     })
            // }

        },
        // 删除单个商品
        deleteGoods(index, goodsIndex) {
            let _that = this;
            this.$confirm({
                title: '您确定要删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    // 编辑走借口删除
                    if (_that.$route.query.planStatus == 10) {
                        let params = {
                            planGroupId: _that.groupList[index].id,
                            goodsSpecId: _that.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex].goodsSpecId
                        }
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/delete', 'post', params).then(res => {
                            if (res.data.code == 200) {
                                _that.$message.success('删除成功！')
                                delete _that.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex];
                                _that.$set(_that.groupList, index, {
                                    ..._that.groupList[index], // 保留其他属性
                                    editNeedOrderPlanGroupDetailParamList: [
                                        ..._that.groupList[index].editNeedOrderPlanGroupDetailParamList.filter((_, i) => i !== goodsIndex)
                                    ]
                                });
                                // _that.getGroupList()
                                // _that.getProgDetail(index)
                            } else {
                                _that.$message.error(res.data.message)
                            }
                        })
                    } else {
                        // 删除指定位置的数据
                        delete _that.groupList[index].editNeedOrderPlanGroupDetailParamList[goodsIndex];
                        // 使用 Vue.set 来确保响应式更新
                        _that.$set(_that.groupList, index, {
                            ..._that.groupList[index], // 保留其他属性
                            editNeedOrderPlanGroupDetailParamList: [
                                ..._that.groupList[index].editNeedOrderPlanGroupDetailParamList.filter((_, i) => i !== goodsIndex)
                            ]
                        });
                        _that.$message.success('删除成功')
                    }

                }
            });


        },
        // 底部按钮删除
        delConfirm() {
            if (!this.delGroupIdList) {
                if(this.$route.query.subType == 101) {
                    this.$message.warning('请选择要删除的方案！')
                } else {
                    this.$message.warning('请选择要删除的组合！')
                }
                return;
            }
            let that = this;
            this.$confirm({
                title: '您确定要删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    // 编辑删除  走接口
                    if (that.$route.query.planStatus == 10) {
                        that.delGroup()
                    } else {
                        // 新增删除
                        that.delGroupIdList.forEach(item => {
                            that.groupList.forEach((it, index) => {
                                if (item == it.id) {
                                    that.groupList.splice(index, 1)
                                }
                            })
                        })

                    }
                }
            });
        },
        // 删除组
        delGroup() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/delete', 'post', {
                planId: this.$route.query.planId,
                planGroupIdList: this.delGroupIdList,
                needOrderDetailId: this.$route.query.needOrderDetailId
            }).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('删除成功！')
                    this.getGroupList();
                    this.getProgDetail();
                }
            })
        },
        // 加入组合确认按钮
        addProjectConfirm() {
            if (this.$route.query.subType == 101) {
                // 货堆
                this.addProjectConfirmHD()
                return false;
            }
            if (this.checkList.length == 0) {
                this.$message.warning('请选择规格');
                return;
            }
            if (!this.checkedGroup) {
                this.$message.warning('请选择组合');
                return;
            }
            let isNext = true;
            let selIndex = -1;
            for (let i = 0; i < this.groupList.length; i++) {
                if (this.groupList[i].id == this.checkedGroup) {
                    selIndex = i;
                    break;  // 找到后立即跳出外层循环
                }
            }
            if (selIndex === -1) return;
            for (let i = 0; i < this.groupList[selIndex].editNeedOrderPlanGroupDetailParamList.length; i++) {
                for (let j = 0; j < this.checkList.length; j++) {
                    if (this.checkList[j] == this.groupList[selIndex].editNeedOrderPlanGroupDetailParamList[i].goodsSpecId) {
                        this.$message.error('当前选择的规格已在该组合中，可直接增加规格数量');
                        isNext = false
                        return;  // 终止整个函数的执行
                    }
                }
            }

            if (!isNext) {
                return false;
            }

            let selectGroupIndex = null;
            this.groupList.forEach((item, idx) => {
                if (item.id == this.checkedGroup) {
                    selectGroupIndex = idx;
                    if (!this.groupList[idx].open) {
                        this.getProgDetail(idx);
                        this.$set(this.groupList[idx], 'open', true)
                    }
                }
            })
            console.log(this.groupList[selectGroupIndex].editNeedOrderPlanGroupDetailParamList, 'selectGroupIndex')
            if (this.$route.query.planStatus == 10) {
                let arr = []
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/save', 'post', {
                    planGroupId: this.checkedGroup,//方案组id
                    goodsId: this.currentProjectData.autarkyId,//商品id
                    goodsSpecIdList: this.checkList,
                    goodsSpecCount: 1,
                    platformDiscountPrice: this.currentProjectData.platformDiscountPrice,
                    // plfPrice:this.currentProjectData.plfPrice
                }).then(res => {
                    if (res.data.code == 200) {
                        let data = res.data.data;
                        this.currentProjectData.goodsSpecVoList.forEach(item => {
                            data.forEach(it => {
                                if (item.goodsSpecId == it.goodsSpecId) {
                                    arr.push({
                                        ...it,
                                        name: this.currentProjectData.name,
                                        goodsSpecCount: 1,
                                        imagePath: this.currentProjectData.imagePath,
                                        supplierId: this.currentProjectData.supplierId,
                                        goodsId: this.currentProjectData.autarkyId,
                                        specsAttributeValue: item.specsAttributeValue,
                                        returnable:this.currentProjectData.returnable,
                                        isQirPic:this.currentProjectData.isQirPic
                                    })
                                }
                            })
                        })
                        let groupIndex = this.groupList.findIndex((item) => item.id == this.checkedGroup)
                        // 筛选选中的组合
                        let selectedGroup = this.groupList.filter((item) => item.id == this.checkedGroup)[0];
                        let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                        selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                        this.groupList.splice(groupIndex, 1, selectedGroup)
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            } else {
                // 传id和优加底价
                let arr = []
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/query', 'post', { goodsSpecIdList: this.checkList, platformDiscountPrice: this.currentProjectData.platformDiscountPrice }).then(res => {
                    let data = res.data.data;
                    this.currentProjectData.goodsSpecVoList.forEach(item => {
                        data.forEach(it => {
                            if (item.goodsSpecId == it.goodsSpecId) {
                                arr.push({
                                    ...it,
                                    name: this.currentProjectData.name,
                                    goodsSpecCount: 1,
                                    imagePath: this.currentProjectData.imagePath,
                                    supplierId: this.currentProjectData.supplierId,
                                    goodsId: this.currentProjectData.autarkyId,
                                    specsAttributeValue: item.specsAttributeValue,
                                    returnable:this.currentProjectData.returnable,
                                    isQirPic:this.currentProjectData.isQirPic
                                })
                            }
                        })
                    })
                    let groupIndex = this.groupList.findIndex((item) => item.id == this.checkedGroup)
                    // 筛选选中的组合
                    let selectedGroup = this.groupList.filter((item) => item.id == this.checkedGroup)[0];
                    let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                    selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                    this.groupList.splice(groupIndex, 1, selectedGroup)
                })
            }
            this.selectVisible = false;
        },
        // 货堆
        addProjectConfirmHD() {
            if (this.checkList.length == 0) {
                this.$message.warning('请选择规格');
                return;
            }
            console.log(this.currentProjectData, 'this.currentProjectData')
            for (let item of this.groupList[0].editNeedOrderPlanGroupDetailParamList) {
                if (this.checkList.some(it => it === item.goodsSpecId)) {
                    this.$message.error('当前选择的规格已在该组合中，可直接增加规格数量');
                    return;  // 终止整个外部循环
                }
            }
            // 传id和优加底价
            let arr = []
            // 编辑添加直接走接口
            if (this.$route.query.planStatus == 10) {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/save', 'post', {
                    planGroupId: this.groupList[0].id,//方案组id
                    goodsId: this.currentProjectData.autarkyId,//商品id
                    goodsSpecIdList: this.checkList,
                    goodsSpecCount: 1,
                    platformDiscountPrice: this.currentProjectData.platformDiscountPrice,
                    // plfPrice:this.currentProjectData.plfPrice
                }).then(res => {
                    if (res.data.code == 200) {
                        request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/query', 'post', { goodsSpecIdList: this.checkList, platformDiscountPrice: this.currentProjectData.platformDiscountPrice }).then(res => {
                            let data = res.data.data;
                            this.currentProjectData.goodsSpecVoList.forEach(item => {
                                data.forEach(it => {
                                    if (item.goodsSpecId == it.goodsSpecId) {
                                        arr.push({
                                            ...it,
                                            goodsSpecCount: 1,
                                            imagePath: this.currentProjectData.imagePath,
                                            supplierId: this.currentProjectData.supplierId,
                                            goodsId: this.currentProjectData.autarkyId,
                                            specsAttributeValue: item.specsAttributeValue,
                                            name: this.currentProjectData.name,
                                        })
                                    }
                                })
                            })
                            let groupIndex = 0
                            // 筛选选中的组合
                            let selectedGroup = this.groupList[0];
                            let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                            selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                            this.groupList.splice(groupIndex, 1, selectedGroup)
                        })
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/query', 'post', { goodsSpecIdList: this.checkList, platformDiscountPrice: this.currentProjectData.platformDiscountPrice }).then(res => {
                    let data = res.data.data;
                    this.currentProjectData.goodsSpecVoList.forEach(item => {
                        data.forEach(it => {
                            if (item.goodsSpecId == it.goodsSpecId) {
                                arr.push({
                                    ...it,
                                    goodsSpecCount: 1,
                                    imagePath: this.currentProjectData.imagePath,
                                    supplierId: this.currentProjectData.supplierId,
                                    goodsId: this.currentProjectData.autarkyId,
                                    specsAttributeValue: item.specsAttributeValue,
                                    name: this.currentProjectData.name,
                                })
                            }
                        })
                    })
                    let groupIndex = 0
                    // 筛选选中的组合
                    let selectedGroup = this.groupList[0];
                    let goodsList = selectedGroup.editNeedOrderPlanGroupDetailParamList || []
                    selectedGroup.editNeedOrderPlanGroupDetailParamList = [...arr, ...goodsList];
                    this.groupList.splice(groupIndex, 1, selectedGroup)
                })
            }

            this.selectVisible = false;
        },

        // 查询方案规格
        // sfa/needOrderPlanGroupDetail/query
        popSelectChange(e) {
            this.checkedGroup = e.target.value;
        },
        // 规格
        specsSelectChange(e) {
            console.log(e)
            // this.prodSelectList = []
        },

        // 查询组列表
        getGroupList() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroup/page', 'post', { page: 0, size: 1500, planId: this.$route.query.planId, needOrderDetailId: this.$route.query.needOrderDetailId }).then(res => {
                console.log(res, 'res')
                if (res.data.code == 200) {
                    let data = res.data.data;
                    data.data.forEach(item => {
                        item.open = false;
                    })
                    this.loading = false;
                    this.groupList = data.data;
                    this.getProgDetail(-1);//查详情 
                }
            })
        },
        // 查询方案详情
        getProgDetail(index) {
            let id = ""
            if (index == -1) {
                id = this.groupList[0].id;
            } else {
                id = this.groupList[index].id;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrderPlanGroupDetail/list/' + id, 'post', {}).then(res => {
                if (index == -1) {
                    this.$set(this.groupList[0], 'editNeedOrderPlanGroupDetailParamList', res.data.data);
                    this.$set(this.groupList[0], 'open', true);
                    this.addCurrentIndex = this.groupList.length;
                } else {
                    this.$set(this.groupList[index], 'editNeedOrderPlanGroupDetailParamList', res.data.data);
                    // this.$set(this.groupList[index], 'open', true); 
                }
                console.log(this.groupList, 'this.groupList')
            })
        },
        // 创建/加入到组
        createProg(item) {
            if (this.$route.query.subType == 102 || this.$route.query.subType == 103) {
                if (this.groupList.length > 0) {
                    this.currentProjectData = item;
                    this.specsList = item.goodsSpecVoList
                    this.selectVisible = true;
                    this.checkedGroup = ""
                    this.checkList = []
                } else {
                    this.$message.warn('请先创建组合')
                }
                return;
            } else {
                this.currentProjectData = item;
                this.specsList = item.goodsSpecVoList
                this.selectVisible = true;
                this.checkedGroup = ""
                this.checkList = []
                // 宅配

            }
            // if (type == 0) {
            //     if (this.groupList.length > 0) {
            //         this.currentProjectData = item;
            //         this.selectVisible = true;
            //     } else {
            //         this.$message.warn('请先创建新方案')
            //     }
            //     return;
            // }
            // this.$message.loading();
            // let url = 'sfa/group/saveGroup'
            // let params = {
            //     "programmeId": this.$route.query.id,
            //     "packName": item.packName,
            //     "autarkyIdlist": [item.id]
            // }
            // request(process.env.VUE_APP_API_BASE_URL + url, 'post', params).then(res => {
            //     this.$message.destroy()
            //     if (res.data.code == 200) {
            //         this.$message.success('创建成功')
            //         this.getGroupList()
            //     } else {
            //         this.$message.error(res.data.message)
            //     }
            // })
        },

        // 查询
        searchChange() {
            this.$message.loading();
            // if(this.firstClassifyId){
            //     this.queryData.classifyIdList[0] = this.firstClassifyId
            // }
            // if(this.secondClassifyId){
            //     this.queryData.classifyIdList[1] = this.secondClassifyId
            // }
            // if(this.tertiaryClassifyId){
            //     this.queryData.classifyIdList[2] = this.tertiaryClassifyId
            // }

            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/goods/list', 'post', this.queryData).then(res => {
                this.$message.destroy()
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.prodList = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.current = data.page + 1;
                    this.total = data.total;
                }
            })
        },
        resetChange() {
            this.queryData = {
                "page": 0,
                "size": 16,
            }
            this.ordCurrent = 0;
            this.typeCurrent = 0;
            this.firstClassifyId = ""
            this.secondClassifyId = ""
            this.searchChange()
        },
        handleOk(e) {
            e.preventDefault()
            this.form.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values)
                }
            })
        },
        normFile(e) {
            console.log('Upload event:', e);
            if (Array.isArray(e)) {
                return e;
            }
            return e && e.fileList;
        },

        getData() {
            this.queryData.programmeId = this.$route.query.id;
            // sfa/needOrder/goods/list
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/needOrder/goods/list', 'post', this.queryData).then(res => {
                let data = res.data.data;
                this.$message.destroy()
                if (res.data.code == 200) {
                    this.prodList = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.search input {
    padding-left: 10px;
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}


::v-deep .page-content {
    padding: 0 !important;
}

.page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}



.left-top {
    position: relative;
    padding: 0 0px 20px;
    box-sizing: border-box;
    width: 20.5vw;
    border-right: 1px solid #f3f3f3;
    overflow-y: scroll;
}

@media (max-width: 1699px) {
    .left-top {
        width: 22vw;
    }

}

.items-right {
    width: 80%;
    padding: 0 27px;
    box-sizing: border-box;
}

.items-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #010101;
    margin: 10px 0;
}

.items-list {
    list-style: none;
    padding: 10px;
    box-sizing: border-box;
    // height: 232px;
    background: #E8ECF9;
    border-radius: 8px;
}

.items-list li {
    display: flex;
    width: 100%;
    margin-bottom: 4px;
}


.items-list span {
    color: #5542F6;
}

.select-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 15px;
    box-sizing: border-box;
}

.select-nav>p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-nav img {
    width: 10px;
    height: 14px;
    margin-left: 6px;
    vertical-align: middle;
}

.select-nav p {
    cursor: pointer;
}

.select-nav p {
    width: 20%;
    text-align: center;
    background: #F7F9FA;
    border-radius: 20px;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #93989A;
    padding: 6px 0px;
}


.prog-tit {
    width: 100%;
    height: 46px;
    background: rgba(98, 113, 235, .15);
    padding: 0 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010101;
    justify-content: space-between;
    margin-bottom: 8px;
}

.prog-tit div {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5542F6;
}

.prog-list {
    padding: 0 15px;
    box-sizing: border-box;
    min-height: 150px;
}

.prog-list li {
    border-radius: 8px;
    border: 1px solid #EBEAED;
    margin-bottom: 8px;
}

.prog-ls-top {
    position: relative;
    padding: 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EBEAED;
}

.prog-ls-top a {
    display: inline-block;
    width: 24px;
    background: #E8ECF9;
    height: 24px;
    margin-right: 6px;
    text-align: center;
}

.prog-ls-top img {
    margin-right: 12px;
    width: 55px;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #EBEAED;
}

.ls-top-tit {
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #0D0A19;
    text-align: left;
    width: 80%;
}

.prog-ls-btm {
    color: #0D0A19;
    padding: 5px 8px;
    box-sizing: border-box;
    // font-size: 13px;
    font-size: .8rem;
}

.prog-ls-btm p {
    margin-bottom: 4px;
}

.prog-ls-btm span {
    color: #84818A;
}


.prog-btm {
    position: fixed;
    z-index: 9;
    width: 20vw;
    bottom: 0;
    background: #eee;
    padding: 10px;
    font-size: 13px;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;

}

.prog-btm button {
    width: 35%;
    height: 36px;
    border-radius: 8px;
}


.items-right {
    height: calc(100vh - 95px);
    overflow-y: scroll;
    padding: 10px 12px;
    box-sizing: border-box;
}

.items-right ul {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
}

.items-right li {
    position: relative;
    // width: 23%;
    // width: 15vw;
    width: 220px;
    margin-right: 5px;
    /* 计算每个项的宽度 */
    // margin-right: 26px;
    border-radius: 14px;
    border: 1px solid #E4E6E8;
    padding: 10px 0;
    margin-bottom: 5px;
}

.items-right .rig-img-box {
    margin: auto;
    width: 128px;
    height: 112px;
}

::v-deep .ant-card-bordered {
    border: none !important;
}

.ant-card-bordered {
    border: none !important;
}

.items-ls-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    border: none !important;
}

.items-btn {
    display: flex;
}

.items-btn button {
    width: 45%;
    height: 36px;
    margin-right: 10px;
    font-size: 13px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    border-radius: 8px;
}

.items-btn button:nth-child(1) {
    border: 1px solid #5542F6;
    color: #5542F6;
}

.items-btn button:nth-child(2) {
    margin-right: 0;
}

.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 6px;
}

.items-empty {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.ant-checkbox-group {
    width: 100%;
}



.select-list {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 4px 0;
}

.select-list img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.select-list ul {
    display: flex;
}

.select-list li {
    cursor: pointer;
    width: 83px;
    height: 36px;
    border-radius: 8px;
    background: #F7F9FA;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #93989A;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.select-list .active-sel {
    background: #5542F6;
    color: #fff;
}



.text-wrap1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-wrap2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 控制显示的行数 */
    overflow: hidden;
}

.close-icon {
    position: absolute;
    right: 10px;
    top: 18px;
    cursor: pointer;
}

.items-tag {
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 20px;
    background: #E8ECF9;
    border-radius: 0px 14px 0px 10px;
    border: 1px solid #5542F6;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #5542F6;
}

.active {
    color: blue;
}

.text-danger {
    color: red;
}




::v-deep .ant-card-body {
    padding: 0;
}


.page {
    overflow: hidden;
}


.prog-mai-item {
    width: 100%;
}


.redCard {
    border: 1px solid red !important;
    position: relative;
    overflow: hidden;

}

.goods-tips {
    position: absolute;
    right: 0;
    top: 0;
    width: 28px;
    text-align: center;
    background: red;
    font-size: 12px;
    border-radius: 0px 0px 0px 10px;
    color: #fff;
}
</style>